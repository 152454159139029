<template>
  <v-app  >
    <!--Flyers-->
    <div>
      <carousel-3d>
        <slide v-for="(items, i) in items" :index="i" :key="i" style="border-radius:10px;border: none;">
          <img :src="items.src">
        </slide>
      </carousel-3d>
    </div>
    <div>
      <v-container>
        <v-row no-gutters>
          <v-col  v-for="(brand,i) in brand" :key="i">
            <div class="mx-auto" max-width="344">
              <v-list-item three-line>
                <v-list-item-avatar
                  tile
                  size="80">
                  <v-img :src="brand.src"/>
                </v-list-item-avatar>
                <v-list-item-content>
                  <h6 style="color:#0F3625;"> {{brand.title}} </h6>
                  <v-list-item-subtitle>{{brand.description}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>   
            </div>
         </v-col>
       </v-row>
      </v-container>
    </div>

    <!-- Products listing -->
    <div class="rounded-xl" style="background-color:#F3EEEA;">
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="4" lg="4" xl="3" v-for="(products,i) in products" :key="i">
            <v-lazy
              v-model="isActive"
              :options="{
                threshold: .2
              }"
              min-height="200"
              transition="fade-transition"
            >   
            <v-card class="mx-auto rounded-xl" flat max-width="344">
              <v-img :src="products.src" height="200px"/>
              <v-card-text>
                <p class="text-h6 text--primary">
                  {{products.title}}
                  <v-btn
                    icon
                    @click="products.reveal = true"
                  >
                    <v-icon>mdi-eye</v-icon>
                  </v-btn>
                </p>
               
                <h4>
                  <v-icon> mdi-currency-inr </v-icon>
                  {{products.price}}
                </h4>
              </v-card-text>
              <v-expand-transition>
                <v-card flat
                  v-if="products.reveal"
                  class="transition-fast-in-fast-out v-card--reveal"
                  style="height: 100%;">
                  <v-card-actions class="pt-0">
                    <v-spacer></v-spacer>
                    <v-btn
                      style="margin:10px;"
                      icon
                      @click="products.reveal = false">
                      <v-icon>mdi-eye-off</v-icon>
                    </v-btn>
                  </v-card-actions>
                  <v-card-text class="pb-0">
                    <div class="text--primary">
                  {{products.description}}
                </div>
                  </v-card-text>
                </v-card>
              </v-expand-transition>
            </v-card> 
          </v-lazy>
        </v-col>
      </v-row>
    </v-container>
  </div>
  <br/><br/><br/>
  <v-card class="mx-auto rounded-xl" flat color="#F3EEEA" style="color:#212121;margin:10px;text-align:justify;">
    <v-container>
      <h1 style="text-align:center;"> About Us </h1>
      <br/>
      <p style="margin:10px;">
        The world is witnessing inconstant food trends , but also facing growing health issues due to excessive use of fertilizers and pesticides in food production. Hence, 'Organic' food has become a viable alternative for an increasing number of customers who are concerned about chemicals in the food and adverse consequences on the environment.
      </p>
  
       <p style="margin:10px;">
        Shreevari products is a certified curated home of organic products where  natural and sustainable products are made available that helps in spreading wellness.  An organisation surging ahead to bring exemplary products that are organic, natural,eco-friendly , and residue free. An ethical supply chain and earthy essence empowers farmers and conscious consumers. An ethical supply chain and earthy essence empowers farmers and conscious consumers.
      </p>
     <v-img src="https://bl3301files.storage.live.com/y4mmGHNnt7_8-BXzz2ZVla-XDBEAB9ecrd_N3DaJqxMqHQs3dE8PhyXn_t6HFzXniQfid0spAqu10WXvDpP0g--02hR9vttnCUCej_4UxNHk6MFIEeP4-GjPGbR_CJPxYC-5l-B6iL6oxknLE4M2mzFRjtsHxDP-AZ_FhxZXMOVxIWsI1KVufVsdo8tA0ZQYmh_?width=891&height=780&cropmode=none" height="500px"/>
      <p style="margin:10px;">
        The team at Shree is spear headed by 	M.R. Naveen Chandra	(a seasoned professional in IT distribution for 18 years and 4 Years of Successful entrepreneurial mentoring in Organic products Supply Chain ) , Arun Kumar 	(  experienced	sales professional in FMCG sales for over 5 years) &  Girish  Nayak ,  ( a backend operation management professional with over 10 years of experience.
        For us, 'Organic' is not a trend , but it is a Lifestyle. 		
      </p>
     
    </v-container>
  </v-card>

  <br/>

</v-app>
</template>

<script>
  export default {
    data () {
      return {
  slide: 0,
        sliding: null,
        alignments: [
        'start',
        'center',
        'end',
      ],
 
     isActive: false,
    brand:[
      {src:'https://bl3301files.storage.live.com/y4mSDN2pYr7RbSz8bGIwhq6VTbGqMzO03VXvmYtRkH0QNxi7LhsQhOSaLlsR3QQ2WIe6ZhNuuMtu6stsyKN9o2WbbExgDXJ6yjZB0lQOC2m0zOUpqQEmTqeSdwZ9uF_3EoeYi0FKMq3rDnKnPOy09Rkg2qGj2LZ5MFX-GSLE88WH5OujJ3D-7-VTSlR8WSZXxPJ?width=132&height=138&cropmode=none',
      title:'Curated Products',
      description:'from Handpicked Sellers'
      },
          {src:'https://bl3301files.storage.live.com/y4mrP2gKKhgYKL0qRKDJH3ga6FUi2mvjPBHxF4Qbphl5pSZyVmIlvOk2tCl6k_oHeAdFehjkCqaxR3MkS2XsQ2zYETKHAgZbOlVxNOdOnlZJngM3jGUDWyKmI5a9QCE-TD75R4Trl55WULfdXIIYduWUM3xX1ag4yt9jAmfySg3tCwMMlD-Ogy2TriLx8LOfT4l?width=154&height=134&cropmode=none',
      title:'Handmade',
      description:'Made with passion by 300+ curators across the country.'
      },
          {src:'https://bl3301files.storage.live.com/y4moH0OkiUDjXoQiYQCjaDHrkHKByBiZ-yAvzULcIdjpNvXxEu3jRWjt9blHi8yy6X9BXUtkKLc51HePQjlO-DEk77gZneOEAoKFqzLWwSe2PAewIbNMbBe6kLK0oWcuJ4jSTiQ4I67m1WRVgNvXFqI0F1xL0c87lBhqK8Z3I0hsa12PHOA7XFuU_j-qcCqJzX4?width=130&height=140&cropmode=none',
      title:'100% Natural',
      description:'Eat local, consume local,closer to nature.'
      },
          {src:'https://bl3301files.storage.live.com/y4mu1tkhrj2lDE6J3WZh_A1IJQXkk6ivUCEqbySdGlpC0mX6TScuZBvlzsfmzh0Aa9haqidmsyTt_vfgkutM01lvtFR5R2S5Z6eEx6mjpwhXeEXmemwBwySZJQfY7Y2IUKMbG9f6fs9IUZY9ZLZvN55a6MEbNP-WgV4rO6wVELzNfFfqAIzUadC2M_OLxNyt4nn?width=154&height=126&cropmode=none',
      title:'Shipping',
      description:'Across bangalore'
      }
    ],
      products:[
        {
            title:'Jaggery Powder',
            price: '148/kg',
              
            deleivery:'delivery in 2 days',
            description:'The Jaggery preparation is purely made with elements like food grade chunam & ladyfinger juice, without any usage of harsh chemicals. This jaggery which is rich in minerals helps in blood purification, reduces digestive issues, boosts energy levels. This jaggery liquid is poured into moulds to arrive at different shapes like bucket, cubes & Powder.',
             src:'https://bl3301files.storage.live.com/y4m6WS10rAcyP-rMygZPcvFGNQPIBUM5pQsj7lqyFqH6f6-rNW76n7SYIhfcDsXyi0SQQX6UG4bM1uKGEZBv5w15LxRA7agnIkMafc7qpRaa74bu-XeIqmXHLFTHszWuOJfULdSQh4mjBzHDomPaDsCve989j1qmRTiQbcu0Zdl02OHKdGetcyh8-M2IVdZ4jdd?width=1024&height=683&cropmode=none',
               reveal: false,
        },
        {
            title:'Jaggery Cube',
            price: '135/kg',
     
            deleivery:'delivery in 2 days',
            description:'The Jaggery preparation is purely made with elements like food grade chunam & ladyfinger juice, without any usage of harsh chemicals. This jaggery which is rich in minerals helps in blood purification, reduces digestive issues, boosts energy levels. This jaggery liquid is poured into moulds to arrive at different shapes like bucket, cubes & Powder.',
             src:'https://bl3301files.storage.live.com/y4m9u-y1wISrYmX06xqtbqrU2Yx4vEUIPpwqOXC393TAuT123F9USP0PqjYiiUhhITf7-BGo2w0otNimyj_RDm6I6X06zYo4nrlJUeDf5Cco3_hAiHEdl0H5NoBjjS8iGeVUq6_GSlc9ehcFJq7QqIQpUgimDjuIYQxrXAMGBJiukl0lI9CcF6YevsePPwfeW_c?width=1024&height=683&cropmode=none',
               reveal: false,
        },
        {
            title:'Jaggery Bucket',
            price: '135/kg',
       
            deleivery:'delivery in 2 days',
            description:'The Jaggery preparation is purely made with elements like food grade chunam & ladyfinger juice, without any usage of harsh chemicals. This jaggery which is rich in minerals helps in blood purification, reduces digestive issues, boosts energy levels. This jaggery liquid is poured into moulds to arrive at different shapes like bucket, cubes & Powder.',
             src:'https://bl3301files.storage.live.com/y4mbebfJaU5Id1f2H-aIgTY-WvJUuGlsspcGpfz4eLTdf6nT9BLVCRZU9EH4LkC09zwwyhHOxfLIghcEqXkbkkcHkFqILVuO-slVl4kSum62010cnsUhgoj_Ej16OLVbhq20DBIkHV1NvQ_radoDeAiMkOBP267bjUKUNMyYCcG72jinEHzBKBTA4F13Gb2roZe?width=1024&height=683&cropmode=none',
               reveal: false,
        },
         {
            title:'Virgin Coconut Oil',
            price: '220 for 250ml',
              
            deleivery:'delivery in 2 days',
            description:'Virgin coconut oil extracted from finest quality coconuts are cooked & the oils extracted. This product provides the guarantee of the purity of its content.',
             src:'https://bl3301files.storage.live.com/y4mDu11hBqgB-G7xhcHeZBpL-_8Sb9xMmRMR3f_h_r7chhfIIoc9fc_Hig3r6WA0qSvxgXLoM0OXzFckXOW8vhtzTw6UYbQAgK2CElDAgnOB9yOM649JSmrNaq56WOfHUo88YnMS6lGBoZki4pc-LkoAHWgozJjhgEGLxF0THgZvPL_PCw7zkaTCJvJZrGj9csE?width=1024&height=683&cropmode=none',
               reveal: false,
        },
        {
            title:'Coconut Oil',
            price: '179 for 500ML',
     
            deleivery:'delivery in 2 days',
            description:'This product is derived from the Ghana which runs on modern technology & electricity. The process of the product & the purity is guaranteed at the source & at our re-packing warehouse. This is an all-purpose non-food grade oil.',
             src:'https://bl3301files.storage.live.com/y4moV_ENTvgSiFwXz82o4mHhHLP0U46XIIZpLfoz5fYbtmpwu5LRVU04uxZ0DuqY30IC1H8rjJlp_YoCuBf6xxuXVJ4mO30gC2zipDjCa7p4-JpgRZ4245Z_JpYc4AJrEvX85hnl_VkxmTIWH4z8ca4jvR_NzQG3Xjj-kqZjj6VvjSq5_HovvieRkKfnWlrh6Kv?width=1024&height=683&cropmode=none',
               reveal: false,
        },
        {
            title:'Hing Granules',
            price: '125 for 25g',
       
            deleivery:'delivery in 2 days',
            description:'It is a resin like gum which is extracted from dried sap of the stem and roots of Ferula plant , that is crushed in a traditional method, between heavy stones or by a hammer. Hing is highly praised for its digestive properties, which is also the reason why it is commonly used in the tempering of dhals or other dishes that may build-up gas in the stomach.',
             src:'https://bl3301files.storage.live.com/y4mx4GbfI9-szRk4uhSDpU7A9z0qldtjQD8dQ0UDzgEE3LQ-3Hd8ip85jzl6VmqX7XNX9kJBbWVyBgFeOWyYpp0R6q91ZJHhSrLdQra3Z1puWvOjXhKE61_96d7ON77sqp3syHMspR3_HirDfA2cR_eRjdd_xhB8--I_69g_csuXJd4ORqxasDztEK3I4IyC9WV?width=1024&height=683&cropmode=none',
               reveal: false,
        },
         {
            title:'Hing Powder',
            price: '125 for 25g',
              
            deleivery:'delivery in 2 days',
            description:'It is a resin like gum which is extracted from dried sap of the stem and roots of Ferula plant , that is crushed in a traditional method, between heavy stones or by a hammer. Hing is highly praised for its digestive properties, which is also the reason why it is commonly used in the tempering of dhals or other dishes that may build-up gas in the stomach.',
             src:'https://bl3301files.storage.live.com/y4mQX6f-iZg83YvtVc8eMKX0wOxkFaEQh_UDApyWlCxYzMfttl1-dKudt0kvSV5wYZuVi60MVLGaowYWlUcz_ZrIzYH447cDR6xW72p6GjjDPhVZA2xc7AFUASAkifP5wB9XhQnDHHdEBSk2oS9ed44hH4rW8X0N58l0MMGi2-hXgDKrirR_2XjqhYHGfvLdcFq?width=1024&height=683&cropmode=none',
               reveal: false,
        },
        {
            title:'Turmeric powder',
            price: '42 for 100g',
            deleivery:'delivery in 2 days',
            description:'Having extensive use ranging from curative to cosmetic properties, and it adds taste to food.',
             src:'https://bl3301files.storage.live.com/y4mg1MncPih3tUpV6CRItfRY-4ovroEi1EB41t23f2Ukd57vRTWVi-ZSZknsGYGXYjXcsD1evD7WPrEH8y96E7BfhoNn9QrmAmwORGsz1XaWQrqPmHe2s8ao-5D1knQq2ieWDHrHPfBUi_oqQnavWDL5mJRYNPU1_vSy6rw3MUKoMSFTBWBuEThOd_pLi_Ss7eu?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Gulkhand',
            price: '179 for 200g',
       
            deleivery:'delivery in 2 days',
            description:'It is an age old custom in Bharat to prepare jams at home under sunlight. It would become a Jar of health and taste. Rose petals are soaked in sugar syrup/Honey and kept in sunlight to allow the rose petals to absorb the sunlight & sugar syrup/Honey. We add the dry fruits to this mix & present the customers with a delightful Gulkand without any preservatives.',
             src:'https://bl3301files.storage.live.com/y4mFRB_UW9mYYHqhnMUIgRZwfqB5Uh8-iFyjqTwRUbKUkOzhywjE51_jBcEJp8M27UalVbCVHns4Ws13WKr_utz6B5gQqEQ_LUVWd7FGHMaE3jB87EOn6wDw4k0jDmzj74BUY-JCm5ob7xidP1-i9eRafy0CAKq5FSXt8oAnsc57q5aE_c4v26adbeh18iaMFzK?width=1024&height=683&cropmode=none',
               reveal: false,
        },
         {
            title:'Deepam Oil',
            price: '249 for 200g',
       
            deleivery:'delivery in 2 days',
            description:'In olden days, one use to light lamps with oil. We at SSE use a summation of Mohua oil(Honge), ground nut oil, hippe oil, coconut oil, & gingelly oil to arrive at the Panch samishta deepam oil, which guarantees the purity of the oils present.',
             src:'https://bl3301files.storage.live.com/y4mswcURwTcXcEm--3x6sUE8OVIqYEbGEegw1bY1x9UD6LvGVbKOg9cBVBUoTvEp32ysk49FiyoC8ZZc5CDT4ksonDF5MNLA4sxFwDV3Rns5kVCu5PHVWlw61RnfDCOnqgWPszerPxFW5rqSU6iZw-mEoHQit8JROrRxgZvlwP4Li2ZkFoMGvhcI-q1xxa2IzkR?width=1024&height=683&cropmode=none',
               reveal: false,
        },
         {
            title:'Cold Pressed Castroil',
            price: '136 for 500ml',
       
            deleivery:'delivery in 2 days',
            description:'Castor oil is made by extracting oil from the seeds of the Ricinus communis plant. It’s commonly used as an additive in foods, medications, and skin care products, as well as an industrial lubricant and biodiesel fuel component. Today, castor oil remains a popular natural laxative.',
             src:'https://bl3301files.storage.live.com/y4m8KSh8Uxa6-2nPd5DZ-0Hw7wqAsfy-Fvwg_XekMUX4D1Rcvhuq5M01j1jOJFSTojPAotW6LSNgeGLxneE7u5EY57EhsAA96wkaT6UvRk_BgL8wkwqNqQt3QZPzRLmQhRKtN43exbRXlKxSa-nSqhlm2x7mbKAoCfoRjfPwE7aOx7buK0DwhNhutbQENMtcTkL?width=1024&height=683&cropmode=none',
               reveal: false,
        },
         {
            title:'Sugar Brown',
            price: '131/kg',
       
            deleivery:'delivery in 2 days',
            description:'Sugar brown can be made by adding molasses syrup to boiling sugar crystals that result from the sugar-refining process. It can also be made by coating white granulated sugar with molasses.',
             src:'https://bl3301files.storage.live.com/y4maYxGrtNwHCuCqyHlu8U26DhBhzBj2wGASl1WTBKvW04KFxKa252s7qrJf8wS-B7q3LwRA5FUofVRAmyNBUex1I_1cLtMl-_EmhJSpb0_lq7bOCn8qO_5qRV9kkXKbnX0iiGZ5F5h3ZuNhmO3EBLRUG2755zX4rudNVOO1Ya7FzVITRkPaEaa6NoDKXBqz2Hd?width=1024&height=683&cropmode=none',
               reveal: false,
        },
          {
            title:'Honey',
            price: '148 for 250g',
              
            deleivery:'delivery in 2 days',
            description:'Brings you the goodness of unadulterated pure honey that is rich in antioxidants and minerals. It acts as an  immunity Booster.',
             src:'https://bl3301files.storage.live.com/y4mJMDIE3ADxqz-j1iq2rTytgxyvjlt9cqhCS8cXjTdzONMzHI4ecTZKAjVslIIHOxn1ZBYWI9_GzWKpQaXP4jMndFvAD1CBhDxZYhk3Lkjh7yocdXNRyCmIvu6Z-sv62nT0Wo9eN9SgyV5lpACWoXQp1i4S3tzxtJ5xQFrMRemg2n6jvm8YCBRdteIxM4QKGFy?width=1024&height=683&cropmode=none',
               reveal: false,
        },
      ],
        items: [
          {
            src: 'https://bl3301files.storage.live.com/y4m-8NkbB1v-K9sCHnqFnZrfm3fna40ZKJGi14E_6snlfQuAjrtMtuwtGTBjtQImz62P9ILojun48MIiKuO6-PlKxSwouOOvPofeR33ZU3mom2TyZS1STOCD1qBc8Nek1n4u-Yi7C2F8RKvixgYwwCKKVtcAgn6msf7tBGEU-MQ-lSzsxxNZp2z2RuzTH2yKlIO?width=1080&height=810&cropmode=none',
          
          },
          {
            src: 'https://bl3301files.storage.live.com/y4mf_Pg85Sr8Bdy12FuebbbkzvLQuCKBcUaS1b-NWNwTUaSUsDM-jwjwUvg1EOR_iQ_qpwBs9fVfouoopu2mZqo1VpbK9JZYrXsX1fh3WXXekr61bphwiwEGV6UJHlIoJLXknfQwoz2SOJbp7sw2RIz8GRlqVFubOdLDGCE1JbPhJdWIcIKpCXLJoCuTSwNQfNb?width=1080&height=810&cropmode=none',
          
          },
          {
            src: 'https://bl3301files.storage.live.com/y4m3kfZ7AZE5OQV26IgGpGrLy2Ky15jOBOHb7oww6DODvhH-3vY851jhAR6s4PPQXRMyLrpIhF8iKRUnK7mUg7ef8uLWPWSh7LHjTsbqJRy50w1jcWZfWm4l1YlW1TkhNV0pzZoVIk3GOyx2voAjHJYWQ7FmxhWoWxtz2fpYBmv84a6fMVcSgIjn1FI57MspgX7?width=1080&height=810&cropmode=none',
          
          },
          {
            src: 'https://bl3301files.storage.live.com/y4mS8_Twrxx4AYWNR58ti6kb-0d9sb5QWkUryOE2cYKUOp6YzyWEkNLapE8nb1HQ9ENe7dhGdjWJEONRT8M_srUf4am2YhmiCSPQzCErNUb99FgzP4YDwKdT8UOCj5S24wS8t4w7JdJlHDo2_Wb52eyRJnpuvXK7_SY7It__egHNhyXVX5X4gicpnlhi2JDlkHZ?width=1080&height=810&cropmode=none',
          
          },
           {
            src: 'https://bl3301files.storage.live.com/y4mLHzXlcjzHJUjJrOPMO7noA14qjrsWT7TPe0p3OuTxcKmo9UQsgfC2LgNj2hjnONtzn08V37Tho3uCsq63N_Ots1e0N_cGJBOgjeBxCz9Mcq_OOoAzLe1LC8u8xmR9NNGXu09piWAYdZCO5MXivUWj_ET0uhsyVZkThSUA0WGa7kpA0oEWQw0nVyvCI47hcOw?width=1080&height=810&cropmode=none',
           
          },
        ],
      }
    },
     }
</script>

 <!-- App.vue -->
<style lang="scss">
.img-fluid {
	max-width: 100%;
	height: auto;
	box-shadow: 2px 2px 5px 0px rgba(0,0,0,0.3);
}
#back {
	text-align: center;
	background-image: url("../assets/background.png");
	background-attachment: fixed;
	background-size: cover;
}
.v-card--reveal {
	bottom: 0;
	opacity: 1 !important;
	position: absolute;
	width: 100%;
}
</style>