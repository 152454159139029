<template>
<div style="background-color:#FCF2E8;border-radius:10px; margin-top:100px;height:500px;">
  <v-row justify="center">
    <v-col
      cols="12"
      sm="10"
      md="8"
      lg="6"
    >
      <v-card style="margin-top:70px;" ref="form">
        <v-card-text>
         <v-text-field
         color="orange darken-2"
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
          ></v-text-field>
            <v-text-field
            color="orange darken-2"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-card-text>
        <v-divider class="mt-12"></v-divider>
        <v-card-actions>
          <v-btn  text
          color="#1B5E20"
           @click="reveal = true"
          >
            Create Account
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="#FF6D00"
            text
            @click="login()"
          >
            Login
          </v-btn>
        </v-card-actions>
         <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 450px;"
      >
        <v-card-text>
       <v-text-field
             
              color="orange darken-2"
              label="Full Name"
              required
            ></v-text-field>
         
            
           
         <v-text-field
         color="orange darken-2"
            v-model="email"
            :rules="[rules.required, rules.email]"
            label="E-mail"
          ></v-text-field>
          <v-text-field
             
             color="orange darken-2"
              label="Mobile"
              required
            ></v-text-field>
            <v-text-field
            color="orange darken-2"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
           <v-text-field
           color="orange darken-2"
            v-model="password"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="show1 ? 'text' : 'password'"
            name="input-10-1"
            label="Confirm Password"
            hint="At least 8 characters"
            counter
            @click:append="show1 = !show1"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pt-0">
          <v-btn
            text
            color="#1B5E20"
            @click="reveal = false"
          >
            Close
          </v-btn>
          <v-spacer></v-spacer>
           <v-btn
            color="#FF6D00"
            text
            @click="submit"
          >
            Signup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
      </v-card>

    </v-col>
  </v-row>
      </div>
</template>

<script>
 import { mapActions } from "vuex";
  export default {
    data () {
      return {
        title: 'Preliminary report',
        show1: false,
         reveal: false,
        email: '',
          first: '',
        last: '',
         password: '',
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      }
    },
    name: "Login",
  methods: {
    ...mapActions("account", ["login"]),
  },
  }
</script>
<style>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>