<template>
<v-app>
 <div>
    
 <v-text-field
 style="margin:40px;"
         light
         solo
         rounded
         append-icon="search"
         placeholder="Search for Products....">
      </v-text-field>
    <v-container >
    <v-row no-gutters  >
     <v-col v-for="(products,i) in products"
      :key="i"
   
       
        >
      <v-lazy
        v-model="isActive"
        :options="{
          threshold: .2
        }"
        min-height="200"
        transition="fade-transition"
      >   
     <v-card   
    
    class="mx-auto"
    max-width="344"
    style="margin:10px"
  >
    <v-img
      :src="products.src"
      height="200px"
    ></v-img>
 <v-card-text>
    
      <p class="text-h6 text--primary">
      {{products.title}}
       <v-btn
        icon
       @click="products.reveal = true"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      </p>
      <div class="text--primary">
       {{products.description}}
      </div>
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
         <h4>  <v-icon> mdi-currency-inr </v-icon>{{products.price}}</h4>
      
      <p>{{products.deleivery}}</p>

    </v-card-text>

    <v-card-actions>
        <v-spacer></v-spacer>
        <v-speed-dial
      v-model="fab"
    :top="top"
      :bottom="bottom"
      :right="right"
     
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="#0D3E29"
          dark
          fab
          small

        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="#DAA86D"
      >
        <v-icon>mdi-cart-arrow-right</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#DAA86D"
      >
        <v-icon>mdi-cart-plus</v-icon>
      </v-btn>

    </v-speed-dial>

    

   
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="products.reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
      >
         <v-card-actions class="pt-0">
           <v-spacer></v-spacer>
           <v-btn
           style="margin:10px;"
        icon
         @click="products.reveal = false"
      >
        <v-icon>mdi-eye-off</v-icon>
      </v-btn>
        
        </v-card-actions>
        <v-card-text class="pb-0">
          <p class="text-h6 text--primary">
            {{products.title}}
          </p>
          <p>{{products.description}} </p>
        </v-card-text>
     
      </v-card>
    </v-expand-transition>
  </v-card> 
      </v-lazy>
        
        </v-col>
      </v-row>
    </v-container>

   
  </div>

</v-app>
</template>

<script>
//import { mapGetters,mapActions } from "vuex";
  export default {
    // name: "Base",
    data () {
      return {
        alignments: [
        'start',
        'center',
        'end',
      ],
    show: false,
     isActive: false,

      products:[
        {
            title:'deepam Oil',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mAkx4lppj1-Ia6GTQ86G24vZfwSYdHnZrxCDCb8xDmdntd1JxNCYnvw944XYvF1TY7OXlE9LowwZjTROeB2OqQ1WkqlLJY2sUpd6AgoIgSppIDTLfapcHbXfIzggnF4JksY0dezCY0hVLb4IXkL1DfI_senDC1-A2U0huniG63-97kBv9250lUlMVhMy45VlK?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'wild honey',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mJMDIE3ADxqz-j1iq2rTytgxyvjlt9cqhCS8cXjTdzONMzHI4ecTZKAjVslIIHOxn1ZBYWI9_GzWKpQaXP4jMndFvAD1CBhDxZYhk3Lkjh7yocdXNRyCmIvu6Z-sv62nT0Wo9eN9SgyV5lpACWoXQp1i4S3tzxtJ5xQFrMRemg2n6jvm8YCBRdteIxM4QKGFy?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Gulkhand',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mFRB_UW9mYYHqhnMUIgRZwfqB5Uh8-iFyjqTwRUbKUkOzhywjE51_jBcEJp8M27UalVbCVHns4Ws13WKr_utz6B5gQqEQ_LUVWd7FGHMaE3jB87EOn6wDw4k0jDmzj74BUY-JCm5ob7xidP1-i9eRafy0CAKq5FSXt8oAnsc57q5aE_c4v26adbeh18iaMFzK?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
         {
            title:'Coconut Oil',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4moV_ENTvgSiFwXz82o4mHhHLP0U46XIIZpLfoz5fYbtmpwu5LRVU04uxZ0DuqY30IC1H8rjJlp_YoCuBf6xxuXVJ4mO30gC2zipDjCa7p4-JpgRZ4245Z_JpYc4AJrEvX85hnl_VkxmTIWH4z8ca4jvR_NzQG3Xjj-kqZjj6VvjSq5_HovvieRkKfnWlrh6Kv?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Jaggery cube',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m9u-y1wISrYmX06xqtbqrU2Yx4vEUIPpwqOXC393TAuT123F9USP0PqjYiiUhhITf7-BGo2w0otNimyj_RDm6I6X06zYo4nrlJUeDf5Cco3_hAiHEdl0H5NoBjjS8iGeVUq6_GSlc9ehcFJq7QqIQpUgimDjuIYQxrXAMGBJiukl0lI9CcF6YevsePPwfeW_c?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Jaggery slab',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mzdom7uvk0oHdlcv4YofEhEuSzzFZEJwyZfm15GnMGq62vi1ptt9JCkXZZUeZd3Bh_0fOsJiUmYgUWX00QHLQwK3-Zf11lNnvFV6kS3RJ0r03hwfWeM94ZJqs-DWnqvQK2_zqwtbAU1u21qjuiRRswl9RtiXqkWkGgG7AVg4BCRhwbDKncA6hDh4dUZNEWtNM?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
         {
            title:'Jaggery bucket',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mbebfJaU5Id1f2H-aIgTY-WvJUuGlsspcGpfz4eLTdf6nT9BLVCRZU9EH4LkC09zwwyhHOxfLIghcEqXkbkkcHkFqILVuO-slVl4kSum62010cnsUhgoj_Ej16OLVbhq20DBIkHV1NvQ_radoDeAiMkOBP267bjUKUNMyYCcG72jinEHzBKBTA4F13Gb2roZe?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Turmeric powder',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mg1MncPih3tUpV6CRItfRY-4ovroEi1EB41t23f2Ukd57vRTWVi-ZSZknsGYGXYjXcsD1evD7WPrEH8y96E7BfhoNn9QrmAmwORGsz1XaWQrqPmHe2s8ao-5D1knQq2ieWDHrHPfBUi_oqQnavWDL5mJRYNPU1_vSy6rw3MUKoMSFTBWBuEThOd_pLi_Ss7eu?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Hing Powder',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mQX6f-iZg83YvtVc8eMKX0wOxkFaEQh_UDApyWlCxYzMfttl1-dKudt0kvSV5wYZuVi60MVLGaowYWlUcz_ZrIzYH447cDR6xW72p6GjjDPhVZA2xc7AFUASAkifP5wB9XhQnDHHdEBSk2oS9ed44hH4rW8X0N58l0MMGi2-hXgDKrirR_2XjqhYHGfvLdcFq?width=5764&height=3843&cropmode=none',
               reveal: false,
        },
      ],
     
      }
    },
  //    computed: {
  //   ...mapGetters("product", ["products"]),
  // },
  
  // methods: {
  //   ...mapActions("product", ["getProducts", "addCart", "removeCart"]),
  // },
  // mounted() {
  //   this.getProducts();
  // }
  }
</script>
 <style lang="scss">
      #back {
            text-align: center;
background-image: url("../assets/background.png");
background-attachment: fixed;
background-size: cover;
        }
  </style>