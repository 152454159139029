import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Shop from '../views/Shop.vue'
import Others from '../views/Others.vue'
import Signin from '../components/Users/Signin'
import Cart from '../components/Orders/Cart.vue'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/shop',
    name: 'Shop',
    component: Shop
  },
  {
    path: '/others',
    name: 'Others',
    component: Others
  },
  {
    path: '/signin',
    name: 'Signin',
    component: Signin
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart
  }
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
