//cs-ecommerce/src/store/index.js
import Vue from 'vue'
import Vuex from 'vuex'
import account from './accounts'
import product from './products'
Vue.use(Vuex)
export default function () {
  const Store = new Vuex.Store({
    modules: {
      account,
      product
    },
    // enable strict mode (adds overhead!)
    // for dev mode only
    strict: process.env.DEV
  })
  return Store
}