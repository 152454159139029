<template>
<v-app>
 <div>
    
 <v-text-field
 style="margin:40px;"
         light
         solo
         rounded
         append-icon="search"
         placeholder="Search for Products....">
      </v-text-field>
    <v-container >
    <v-row no-gutters  >
      <v-col v-for="(products,i) in products"
      :key="i"
   
       
        >
         <v-lazy
        v-model="isActive"
        :options="{
          threshold: .5
        }"
        min-height="200"
        transition="fade-transition"
      >   
    <v-card   
    
    class="mx-auto"
    max-width="344"
    style="margin:10px"
  >
    <v-img
      :src="products.src"
      height="200px"
    ></v-img>
 <v-card-text>
    
      <p class="text-h6 text--primary">
      {{products.title}}
       <v-btn
        icon
       @click="products.reveal = true"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
      </p>
      <div class="text--primary">
       {{products.description}}
      </div>
        <v-rating
          :value="4.5"
          color="amber"
          dense
          half-increments
          readonly
          size="14"
        ></v-rating>
         <h4>  <v-icon> mdi-currency-inr </v-icon>{{products.price}}</h4>
      
      <p>{{products.deleivery}}</p>

    </v-card-text>

    <v-card-actions>
        <v-spacer></v-spacer>
        <v-speed-dial
      v-model="fab"
    :top="top"
      :bottom="bottom"
      :right="right"
     
      :left="left"
      :direction="direction"
      :open-on-hover="hover"
      :transition="transition"
    >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="#0D3E29"
          dark
          fab
          small

        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-plus
          </v-icon>
        </v-btn>
      </template>
      <v-btn
        fab
        dark
        small
        color="#DAA86D"
      >
        <v-icon>mdi-cart-arrow-right</v-icon>
      </v-btn>
      <v-btn
        fab
        dark
        small
        color="#DAA86D"
      >
        <v-icon>mdi-cart-plus</v-icon>
      </v-btn>

    </v-speed-dial>

    

   
    </v-card-actions>

    <v-expand-transition>
      <v-card
        v-if="products.reveal"
        class="transition-fast-in-fast-out v-card--reveal"
        style="height: 100%;"
      >
        <v-card-actions class="pt-0">
           <v-spacer></v-spacer>
           <v-btn
           style="margin:10px;"
        icon
         @click="products.reveal = false"
      >
        <v-icon>mdi-eye-off</v-icon>
      </v-btn>
        
        </v-card-actions>
        <v-card-text class="pb-0">
          
          <p class="text-h6 text--primary">
            {{products.title}}
          </p>
          <p>{{products.description}} </p>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card> 
         </v-lazy>
        </v-col>
      </v-row>
    </v-container>

   
  </div>

</v-app>
</template>

<script>
  export default {
    data () {
      return {
        alignments: [
        'start',
        'center',
        'end',
      ],
    show: false,
     isActive: false,

      products:[
        {
            title:'Vitro',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mej4mG1ul7T9Zl0Yl28pgrB2CGBEnwGtNilbCWcZ7Zz-KA62I5LZTXwW8VdDfJ8RtJP_MUKeE0XfFqyU6_pfVVfI_8Rbp0AYhef3nf17HINfdeimPuchJQHsi5-y_N5NzQRUTBruMzwgk0A3BbE9lZJ6-IbrcYuMtW_Jf4dE9FmZK0Kq212KNQkldFq4SBXjw?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m7QmWCaxv22J1sezC2gy13x_pDjuXutXfiEuEV0G_8Hnf1qWIfUeSFZdgUMVZdstyMLrXxHXkSElNQ3slXQMXN_JEblfDhVUHJqk3Jc3aUzOZa34x6rDEN90BCztE4_1n4OKZC2FO_Dz_1hy-XCbAByskRX0ST3ATegbAg3Bu0hPg4lZadIN1OvRR9Dy83r4O?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mej4mG1ul7T9Zl0Yl28pgrB2CGBEnwGtNilbCWcZ7Zz-KA62I5LZTXwW8VdDfJ8RtJP_MUKeE0XfFqyU6_pfVVfI_8Rbp0AYhef3nf17HINfdeimPuchJQHsi5-y_N5NzQRUTBruMzwgk0A3BbE9lZJ6-IbrcYuMtW_Jf4dE9FmZK0Kq212KNQkldFq4SBXjw?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
         {
            title:'Vitro',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m7QmWCaxv22J1sezC2gy13x_pDjuXutXfiEuEV0G_8Hnf1qWIfUeSFZdgUMVZdstyMLrXxHXkSElNQ3slXQMXN_JEblfDhVUHJqk3Jc3aUzOZa34x6rDEN90BCztE4_1n4OKZC2FO_Dz_1hy-XCbAByskRX0ST3ATegbAg3Bu0hPg4lZadIN1OvRR9Dy83r4O?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mej4mG1ul7T9Zl0Yl28pgrB2CGBEnwGtNilbCWcZ7Zz-KA62I5LZTXwW8VdDfJ8RtJP_MUKeE0XfFqyU6_pfVVfI_8Rbp0AYhef3nf17HINfdeimPuchJQHsi5-y_N5NzQRUTBruMzwgk0A3BbE9lZJ6-IbrcYuMtW_Jf4dE9FmZK0Kq212KNQkldFq4SBXjw?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m7QmWCaxv22J1sezC2gy13x_pDjuXutXfiEuEV0G_8Hnf1qWIfUeSFZdgUMVZdstyMLrXxHXkSElNQ3slXQMXN_JEblfDhVUHJqk3Jc3aUzOZa34x6rDEN90BCztE4_1n4OKZC2FO_Dz_1hy-XCbAByskRX0ST3ATegbAg3Bu0hPg4lZadIN1OvRR9Dy83r4O?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
         {
            title:'Vitro',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mej4mG1ul7T9Zl0Yl28pgrB2CGBEnwGtNilbCWcZ7Zz-KA62I5LZTXwW8VdDfJ8RtJP_MUKeE0XfFqyU6_pfVVfI_8Rbp0AYhef3nf17HINfdeimPuchJQHsi5-y_N5NzQRUTBruMzwgk0A3BbE9lZJ6-IbrcYuMtW_Jf4dE9FmZK0Kq212KNQkldFq4SBXjw?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m7QmWCaxv22J1sezC2gy13x_pDjuXutXfiEuEV0G_8Hnf1qWIfUeSFZdgUMVZdstyMLrXxHXkSElNQ3slXQMXN_JEblfDhVUHJqk3Jc3aUzOZa34x6rDEN90BCztE4_1n4OKZC2FO_Dz_1hy-XCbAByskRX0ST3ATegbAg3Bu0hPg4lZadIN1OvRR9Dy83r4O?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
        {
            title:'Vitro',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mej4mG1ul7T9Zl0Yl28pgrB2CGBEnwGtNilbCWcZ7Zz-KA62I5LZTXwW8VdDfJ8RtJP_MUKeE0XfFqyU6_pfVVfI_8Rbp0AYhef3nf17HINfdeimPuchJQHsi5-y_N5NzQRUTBruMzwgk0A3BbE9lZJ6-IbrcYuMtW_Jf4dE9FmZK0Kq212KNQkldFq4SBXjw?width=6000&height=4000&cropmode=none',
               reveal: false,
        },
      ],
      shop:[{
        src:'https://firebasestorage.googleapis.com/v0/b/dev-meetups-8db90.appspot.com/o/SUN_9134.png?alt=media&token=82187587-1c81-47d5-ac63-b7028e49f6bf',
        quote:'Discover Real Organic Medicines',
        hex:'#FFD700'
      },{
        src:'https://firebasestorage.googleapis.com/v0/b/dev-meetups-8db90.appspot.com/o/honey.png?alt=media&token=5d9e403a-21a2-4a4a-a557-922707721677',
         quote:'Best Honey With Premium Quality',
       
        hex:'#FF8F03'
      },
      {
        src:'https://firebasestorage.googleapis.com/v0/b/dev-meetups-8db90.appspot.com/o/SUN_9134.png?alt=media&token=82187587-1c81-47d5-ac63-b7028e49f6bf',
        quote:'Discover Real Organic Medicines',
        hex:'#FFD700'
      }],
      }
    },
  }
</script>
 <style lang="scss">
      #back {
            text-align: center;
background-image: url("../assets/background.png");
background-attachment: fixed;
background-size: cover;
        }
  </style>