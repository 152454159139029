<template>
<div style="background-color:#DCEDC8;border-radius:10px;">
   <v-container>
     <v-row>
       <v-col cols="6">
    <h6 style="text-align:left;margin:10px; color:#0F3625">Your Shopping list</h6 >
       </v-col>
       <v-col cols="6">
           <router-link
        to="/shop"
        style="color:#0F3625;"
      >
     <h6 style="text-align:right;margin:10px;color:#0F3625;">Checkout >></h6 >
           </router-link>
       </v-col>
       </v-row>
       </v-container>
       <v-container >
    <v-row no-gutters  >
      <v-col cols="12"
   
       
        >

  <v-card
    class="mx-auto"
    max-width="100%"
    style="margin:30px;"
  >
    <v-list-item three-line>
         <v-list-item-avatar
        tile
        size="200"
      >
        <v-img
      src="https://bl3301files.storage.live.com/y4muYf-7oe5WrzRJvvwd907YOOLSyR5KN3KDHAcEcdpsPVYcpPE-78TwGm-lq3yvMGgQy9tieQUSEQoLFylnlytuGyR5EmyWW15pGMtaMxi5jkBLyIAOfWEs-jMcjnPwrWkpsq_8YeBcBPrbp_qFF97mWkBn3ZbLvFrLJPTHBsIsVBEhuhJ8CImSMsPdIdgP88L?width=6000&height=4000&cropmode=none"
     
    ></v-img>
      </v-list-item-avatar>
      <v-list-item-content>
        
        <v-list-item-title class="text-h5" style="text-align:left;">
          Deepam oil
         
        </v-list-item-title>
        <div style="max-width:300px;">
                <v-slider
      v-model="ex3.val"

       prepend-icon="mdi-weight-kilogram"
      :thumb-color="ex3.color"
      thumb-label="always"
    ></v-slider>
        </div>
        <div style="text-align:left;">Greyhound divisely hello coldly fonwderfully</div>
      </v-list-item-content>

     
    </v-list-item>

    <v-card-actions>
      <v-btn
        outlined
        rounded
        text
      >
        Button
      </v-btn>
    </v-card-actions>
  </v-card>
      </v-col>
    </v-row>
       </v-container>
</div>
</template>
<script>
  export default {
    data () {
      return {
        ex3: { label: 'thumb-color', val: 1, color: 'red' },
      }
    },
  }
</script>