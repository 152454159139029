 <template>
    <div id="app">

       <!-- Parent toolbar -->
      <v-toolbar
        flat
        prominent
        color="#fff">

        <!-- Logo section start -->
          <v-container
           fluid
           style="height:120px; width:120px;">
            <router-link
              to="/">
                <v-img
                  max-width="120px"
                  max-height="120px"
                  :src="require('@/assets/logo.png')" alt="logo"/>
            </router-link>
          </v-container>
        <!-- Logo section end -->
          <v-spacer></v-spacer>
          
          <h4 style="margin:50px;">Contact: +91 - 9513993993</h4>
      
        <!-- Menu action icons section start  
        <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-width="200"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }" > 
          <div 
            style="margin-top:40px;">
                <v-btn
                  class="ma-2"
                  large
                     v-bind="attrs"
                    v-on="on"
                  icon
                  color="teal">
                    <v-badge
                      content="3"
                      top
                      color="#DAA86D"
                      offset-x="12"
                      offset-y="5">
                        <v-icon color="#0D3E29">mdi-cart</v-icon>
                    </v-badge>
                </v-btn>
          </div>
                </template>
    <v-card style="background-color:#DCEDC8;">
      <h3 style="text-align:left;padding:10px;color:#0D3E29"> Your Cart </h3>
        <v-card style="margin:10px;"  v-for="(products,i) in products"
      :key="i">
      
        <v-list>
          <v-list-item >
            <v-list-item-avatar    tile
        size="80">
              <img
                :src="products.src"
                style="width:150px;"
                alt="John"
              >
            </v-list-item-avatar>
       
     
            <v-list-item-content three-line style="text-align:left;">
              
              <v-list-item-title style="color:#0D3E29;">{{products.title}}</v-list-item-title>
             
              <v-list-item-subtitle style="color:#0D3E29;">1 X {{products.price}}</v-list-item-subtitle>
             <v-select
            color="orange darken-2"
          :items="products.items"
          label="Qty."
        ></v-select>
            </v-list-item-content>
<v-row  
          align="center"
          justify="end"
         
        >
          <v-icon class="mr-1">
           mdi-currency-inr
          </v-icon>
          <span class="subheading mr-2" style="color:#0D3E29;">{{products.price}}</span>
              <v-icon color="red" class="mr-1">
           mdi-trash-can
          </v-icon>
        </v-row>
            
     
          </v-list-item>

        </v-list>
        </v-card>
   

        <v-divider></v-divider>

        <v-card-actions>
            <v-list style="background-color:#DCEDC8;">
          <v-list-item >
           <v-list-item-content three-line style="text-align:left;">
             <v-list-item-title style="color:#0D3E29;">Subtotal:  <v-icon>
           mdi-currency-inr
          </v-icon> 515</v-list-item-title>
             <v-list-item-subtitle style="color:#0D3E29;">deliver charges **</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
            </v-list>
          <v-spacer></v-spacer>
          <v-btn
            color="#0D3E29"
            text
            outlined
            @click="menu = false"
          >
            Check out
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>-->
           
 <!-- <div 
            style="margin-top:40px;">
            <router-link
              to="/signin"
              tag="v-btn">
                <v-btn
                  style="margin-left:20px;"
                  class="ma-2"
                  large
                  icon
                  color="indigo">
                    <v-icon color="#0D3E29">mdi-account </v-icon>
                </v-btn>
            </router-link>
          </div> -->
          <!-- Menu action icons section end -->
      </v-toolbar>
      <!-- Parent toolbar end -->

      
      <v-app>
        <!-- Child appbar start -->
        <!-- <v-app-bar
          color="#0D3E29">
            <v-tabs
              centered
              class="ml-n9"
              dark
              color="#DAA86D">
                <v-tab
                  v-for="item in menuItems"
                  :key="item.title"
                  :to="item.link">
                    {{ item.title }}
                </v-tab>
            </v-tabs>
        </v-app-bar> -->
        <!-- Child appbar end -->

        <!-- Main content view start -->
        <v-main >
          <v-container>
            <v-row>
              <v-col
                cols="12">
                  <v-sheet
                    rounded="lg"
                    min-height="70vh"
                    class="pa-1">
                     <!-- Router view start -->
                      <router-view></router-view>
                    <!-- Router view end -->
                  </v-sheet>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
         <!-- Main content view end -->

         <!-- Footer view start  -->
         <v-footer
         color="#F3EEEA"
      class=" text-center d-flex flex-column"
    >
      <div>
        <v-btn
          v-for="icon in icons"
          :key="icon"
          :icon="icon"
          class="mx-4"
          variant="text"
        ></v-btn>
      </div>
  
  
      <v-divider></v-divider>
  
      <div style="color:#212121">
        {{ new Date().getFullYear() }} — <strong>Shreevari Products</strong>
      </div>
    </v-footer>
      </v-app>
    </div>  
</template>

<script>
import { mapGetters } from "vuex";
 export default {
    data: () => ({
       sideNav: false,
       icons: [
        'mdi-facebook',
        'mdi-twitter',
        'mdi-linkedin',
        'mdi-instagram',
      ],
      menuItems: [
            { title: 'Home', link: '/'},
             { title: 'Shop', link: '/shop'},
            {title: 'Others', link: '/Others'}
             ],
       ex3: { label: 'Qty.', val: 1, color: 'red',  min: 1,
        max: 10,
       },
        products:[
        {
            title:'Jaggery',
            price: '135',
              
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4m6WS10rAcyP-rMygZPcvFGNQPIBUM5pQsj7lqyFqH6f6-rNW76n7SYIhfcDsXyi0SQQX6UG4bM1uKGEZBv5w15LxRA7agnIkMafc7qpRaa74bu-XeIqmXHLFTHszWuOJfULdSQh4mjBzHDomPaDsCve989j1qmRTiQbcu0Zdl02OHKdGetcyh8-M2IVdZ4jdd?width=6000&height=4000&cropmode=none',
               reveal: false,
                items: [1,2,3,4,5,6,7,8,9,10],
        },
        {
            title:'wild honey',
            price: '145',
     
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mJMDIE3ADxqz-j1iq2rTytgxyvjlt9cqhCS8cXjTdzONMzHI4ecTZKAjVslIIHOxn1ZBYWI9_GzWKpQaXP4jMndFvAD1CBhDxZYhk3Lkjh7yocdXNRyCmIvu6Z-sv62nT0Wo9eN9SgyV5lpACWoXQp1i4S3tzxtJ5xQFrMRemg2n6jvm8YCBRdteIxM4QKGFy?width=6000&height=4000&cropmode=none',
              reveal: false,
              items: [1,2,3,4,5,6,7,8,9,10],
        },
        {
            title:'Gulkhand',
            price: '235',
       
            deleivery:'delivery in 2 days',
            description:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
             src:'https://bl3301files.storage.live.com/y4mFRB_UW9mYYHqhnMUIgRZwfqB5Uh8-iFyjqTwRUbKUkOzhywjE51_jBcEJp8M27UalVbCVHns4Ws13WKr_utz6B5gQqEQ_LUVWd7FGHMaE3jB87EOn6wDw4k0jDmzj74BUY-JCm5ob7xidP1-i9eRafy0CAKq5FSXt8oAnsc57q5aE_c4v26adbeh18iaMFzK?width=6000&height=4000&cropmode=none',
              reveal: false,
              items: [1,2,3,4,5,6,7,8,9,10],
        },
        
       
        
      ],
    }),
     computed: {
    ...mapGetters("account", ["user"]),
    ...mapGetters("product", ["cart"])
  }
  }
</script>

 <!-- App.vue -->
<style lang="scss">
body {
	text-align: center;
  background-color: #F3EEEA;
}
#app {
	text-align: center;
	font-family: Roboto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>